/deep/ .el-dialog__body {
  .content-wrapper {
    & > .acount-wrapper {
      background-color: #fff;
      color: #f4110f;
    }

    & > .select-wrapper > label {
      text-transform: uppercase;
      color: $future-blue;
    }

    & > .select-wrapper,
    & > .btn-wrapper {
      margin-top: 30px;
    }

    & > .btn-wrapper {
      text-align: center;

      & > button {
        width: 245px;
      }
    }
  }
}
